import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"];

 
  copy(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.linkTarget.dataset.value);
    const notify = new CustomEvent("toast");
    window.dispatchEvent(notify);
  }

  get alert() {
    return document.getElementById("pageAlert");
  }
}