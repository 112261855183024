import Notification from "@stimulus-components/notification"

export default class extends Notification {
  connect() {
    super.connect()
  }
  async hide() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  
    await this.leave()
  
    this.element.classList.add("hidden");
  }
}


